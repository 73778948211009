import styled from '@emotion/styled';
import { compose, background, border, bottom, boxShadow, color, flexbox, grid, layout, left, position, right, space, shadow, top, typography } from 'styled-system';

/**
 * Enum used in styled components and mostly used in combination with styled-breakpoints package.
 */
var Breakpoint;
(function (Breakpoint) {
    Breakpoint["Mobile"] = "mobile";
    Breakpoint["Desktop"] = "desktop";
})(Breakpoint || (Breakpoint = {}));

const composedHelpers = compose(background, border, bottom, boxShadow, color, flexbox, grid, layout, left, position, right, space, shadow, top, typography);
const Box = styled.div `
    ${composedHelpers};
`;
const Flex = styled(Box) `
    display: flex;
`;
const Spacer = styled.div(compose(space, flexbox));

const composedTypographyHelpers = compose(bottom, color, flexbox, layout, left, position, right, space, top, typography);
const Text = styled.span `
    ${composedTypographyHelpers};
`;
const Paragraph = styled.p `
    margin: 0;
    ${composedTypographyHelpers};
`;

const _breakpoints = ["360px", "768px"];
//aliases [https://styled-system.com/responsive-styles]
_breakpoints[Breakpoint.Mobile] = _breakpoints[0];
_breakpoints[Breakpoint.Desktop] = _breakpoints[1];
const standardBreakpoints = { breakpoints: _breakpoints };

/**
 * Utility function that handles default value (at 0 index) for styled-system's array notation.
 * @param valuesForBreakpoints - Accepts generic type {T} or null.
 */
const responsive = (...valuesForBreakpoints) => {
    return [valuesForBreakpoints[0], ...valuesForBreakpoints];
};

export { Box, Breakpoint, Flex, Paragraph, Spacer, Text, composedHelpers, composedTypographyHelpers, responsive as r, responsive, standardBreakpoints };
