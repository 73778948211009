const __DEV__ = process.env.NODE_ENV !== "production";
const __TEST__ = process.env.NODE_ENV === "test";

var UnicodeCharacter;
(function (UnicodeCharacter) {
    UnicodeCharacter["NonBreakingSpace"] = "\u00A0";
})(UnicodeCharacter || (UnicodeCharacter = {}));

/**
 * Abstracted version of console.log for logging only on specified environment.
 * @param args
 */
function logger(...args) {
    console.log("\x1b[34m%s\x1b[0m", "[@ssg-b2b]:", ...args);
}

/**
 * Utility no-op function useful for providing fallback for optional callbacks.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => { };

/**
 * Utility function for checking if value is strictly an object
 * @param valueToCheck
 */
function isPlainObject(valueToCheck) {
    return Object.prototype.toString.call(valueToCheck) === "[object Object]";
}

/**
 * Utility function for checking if value is strictly a number
 * @param valueToCheck
 */
function isNumber(valueToCheck) {
    return typeof valueToCheck === "number" && !Number.isNaN(valueToCheck);
}

const ordinalSuffix = (value) => {
    const j = value % 10;
    const k = value % 100;
    if (j == 1 && k != 11) {
        return value + "st";
    }
    if (j == 2 && k != 12) {
        return value + "nd";
    }
    if (j == 3 && k != 13) {
        return value + "rd";
    }
    return value + "th";
};

/**
 * Utility function to calculate stat and its label for participant stats-related progress bars.
 * @param recordStatOutcomeProps - {RecordStatOutcomeProps} | null
 */
const recordStatOutcome = (recordStatOutcomeProps) => {
    const { over, under } = recordStatOutcomeProps !== null && recordStatOutcomeProps !== void 0 ? recordStatOutcomeProps : {};
    if (!isNumber(over) || !isNumber(under)) {
        return [null, null];
    }
    const biggerStatValue = Math.max(over, under);
    const statPercentage = Math.round((biggerStatValue * 100) / (over + under));
    const percentageLabel = over >= under ? "Over" : "Under";
    return [statPercentage, percentageLabel];
};

const MIN_PROGRESSBAR_WIDTH_PERCENT = 5;
const countProgressBarPercentageWithLine = (line, value) => {
    if (typeof value === "undefined" || value === null) {
        return 0;
    }
    const [leftBoundary, rightBoundary] = countBoundaryValues(line);
    const percentage = Math.round(100 * ((value - leftBoundary) / (rightBoundary - leftBoundary)));
    return limitPercentage(percentage, MIN_PROGRESSBAR_WIDTH_PERCENT, 100);
};
const countBoundaryValues = (line) => {
    const boundaryPercentage = countBoundaryPercentage(line);
    return [line * ((100 - boundaryPercentage) / 100), line * ((100 + boundaryPercentage) / 100)];
};
const countBoundaryPercentage = (line) => {
    if (line > 50) {
        return 20;
    }
    else if (line > 20) {
        return 30;
    }
    else if (line > 10) {
        return 40;
    }
    else if (line > 2) {
        return 50;
    }
    return 100; // for "2" or less than "2"
};
const limitPercentage = (value, minValue, maxValue) => {
    if (value <= minValue) {
        return minValue;
    }
    if (value >= maxValue) {
        return maxValue;
    }
    return value;
};

function transformDiffusionTopic(topic) {
    const replacements = [
        [/\?/g, "<Q>"],
        [/\*/g, "<A>"],
        [/!/g, "<E>"],
        [/\$/g, "<D>"],
        [/\(/g, "<O>"],
        [/\\/g, "<B>"],
        [/\)/g, "<C>"],
        [/\+/g, "<P>"],
        [/\^/g, "<X>"],
        [/\|/, "<I>"],
        [/,/g, "<M>"],
        [/\./g, "<F>"],
        [/\[/g, "<S>"],
        [/\]/g, "<R>"],
        [/À|Á|Â|Ã|Ä|Å|à|á|â|ã|ä|å/g, "A"],
        [/Æ|æ/g, "A,E"],
        [/Ç|ç/g, "C"],
        [/È|É|Ê|Ë|è|é|ê|ë/g, "E"],
        [/Ì|Í|Î|Ï|ì|í|î|ï/g, "I"],
        [/Ð|ð/g, "D,H"],
        [/Ò|Ó|Ô|Õ|Ö|ò|ó|ô|õ|ö|ø/g, "O"],
        [/Ù|Ú|Û|Ü|ù|ú|û|ü/g, "U"],
        [/Ý|ý|ÿ|Ÿ/g, "Y"],
        [/Ñ|ñ/g, "N"],
        [/Ø|ø/g, "O"],
        [/Þ|þ/g, "T,H"],
        [/ß/g, "S,S"],
        [/[a-z]/g, (v) => v.toUpperCase()],
        [/[\u0000-\u001f]/g, " "]
    ];
    const _topic = replacements.reduce((currTopic, replacement) => {
        const regex = replacement[0];
        return currTopic.replace(regex, replacement[1]);
    }, topic);
    // MS: In the docs <O>A<F>W<C> is required, however it will not actually work with this string. :thinking_face:
    return _topic.replace(" <O>A<F>W<C>", "").toUpperCase();
}

var RPAssetsSportName;
(function (RPAssetsSportName) {
    RPAssetsSportName["Football"] = "football";
    RPAssetsSportName["AmericanFootball"] = "americanfootball";
    RPAssetsSportName["Basketball"] = "basketball";
    RPAssetsSportName["Baseball"] = "baseball";
    RPAssetsSportName["IceHockey"] = "icehockey";
})(RPAssetsSportName || (RPAssetsSportName = {}));
var RPAssetsCollection;
(function (RPAssetsCollection) {
    RPAssetsCollection["Team"] = "teambadges";
    RPAssetsCollection["Competition"] = "competitionbadges";
})(RPAssetsCollection || (RPAssetsCollection = {}));
/**
 * Utility for composing urls for assets kept at rp-assets CDN.
 */
class RPAssets {
    static getUrl(sport, collection, id) {
        return `${RPAssets.cdnBaseUrl}/${sport}/${collection}/${id}.png`;
    }
    static getTeamBadgeUrl(sport, id) {
        return RPAssets.getUrl(sport, RPAssetsCollection.Team, id);
    }
    static getCompetitionBadgeUrl(sport, id) {
        return RPAssets.getUrl(sport, RPAssetsCollection.Competition, id);
    }
}
RPAssets.cdnBaseUrl = "https://www.rp-assets.com";

export { RPAssets, RPAssetsSportName, UnicodeCharacter, __DEV__, __TEST__, countProgressBarPercentageWithLine, isNumber, isPlainObject, logger, noop, ordinalSuffix, recordStatOutcome, transformDiffusionTopic };
